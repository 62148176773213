.container {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.safe {
  border-radius: 8px !important;
  border: 1px solid var(--color-border-light) !important;
  height: 72px;
}

.open {
  padding-left: 16px;
  border: 1px solid var(--color-secondary-light);
  border-left: 6px solid var(--color-secondary-light);
  background-color: var(--color-secondary-background) !important;
}

.safe:hover {
  background-color: var(--color-secondary-background);
}

.container :global .MuiListItemSecondaryAction-root {
  right: 24px;
}

.withPendingButtons :global .MuiListItemSecondaryAction-root {
  right: 74px;
}

@media (max-width: 400px) {
  .withPendingButtons :global .MuiListItemSecondaryAction-root {
    right: 48px;
  }
}
